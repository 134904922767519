export enum SystemMetric {
  EnergyProduction = 'energyProduction',
  EnergyConsumption = 'energyConsumption',
  EnergyExport = 'energyExport',
  EnergyImport = 'energyImport',
  PowerProduction = 'powerProduction',
  PowerConsumption = 'powerConsumption',
  PowerExport = 'powerExport',
  PowerImport = 'powerImport',
  Storage = 'storage',
  Devices = 'devices',
  Metadata = 'metadata',
}

// type of JSON field `condition` of SystemAlert
export type SystemAlertCondition = {
  metric: SystemMetric; // currently only supported are SystemMetric.energyProduction, SystemMetric.energyConsumption
  comparator: 'less' | 'more';
  percent: number; // 1...1000
  days: number; // 1...30
};
