import styled from 'styled-components';
import { Panel } from '../../ui/Panel';

export const Container = styled(Panel)`
  background-color: #ffffff;
`;

export const Header = styled.div`
  margin-bottom: 40px;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 40px;
`;

export const SystemSyncDate = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 10px;
  font-weight: 400;
  margin-top: 8px;
`;

export const NextSync = styled.div`
  color: #828d9a;
`;

export const ChartTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1d1d35;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const PeriodControls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  justify-content: space-between;
  > *:last-child {
    min-width: 0;
  }

  @media (min-width: 768px) {
    gap: 40px;
    justify-content: flex-end;

    > *:last-child {
      width: 125px;
      flex: 0 0 125px;
      min-width: 125px;
      max-width: 125px;
    }
  }
`;

export const PeriodAndArrows = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
`;

export const Chart = styled.div`
  min-height: 283px;
  height: 283px;
  width: 100%;
`;

export const ChartLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ChartLegendLabels = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ChartLegendLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 12px;
  font-weight: 400;
  color: #1d1d35;
`;

export const HomeImageAndStats = styled.div`
  position: relative;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 150px;

  @media (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const HomeImage = styled.img`
  width: 100%;
  height: auto;

  @media (min-width: 768px) {
    width: 70%;
  }
`;

const Annotation = styled.div`
  position: absolute;
  text-align: center;
  z-index: 1;
`;

const AnnotationLine = styled.div`
  width: 1px;
  position: absolute;
  z-index: 1;
`;

export const SolarAnnotation = styled(Annotation)`
  left: 26%;
  top: 0;

  @media (min-width: 768px) {
    left: calc(0.26 * 70%);
    top: 8%;
  }
`;

export const SolarLine = styled(AnnotationLine)`
  background-color: #ffbd13;
  left: 25%;
  top: 0;
  height: calc(35px + 0.43 * (100% - 35px - 150px));

  @media (min-width: 768px) {
    left: calc(0.25 * 70%);
    top: 8%;
    height: 35%;
  }
`;

export const ConsumedAnnotation = styled(Annotation)`
  left: 66%;
  top: 0;

  @media (min-width: 768px) {
    left: calc(0.66 * 70%);
    top: 8%;
  }
`;

export const ConsumedLine = styled(AnnotationLine)`
  background-color: #c8c8d3;
  left: 65%;
  top: 0;
  height: calc(35px + 0.4 * (100% - 35px - 150px));

  @media (min-width: 768px) {
    left: calc(0.65 * 70%);
    top: 8%;
    height: 33%;
  }
`;

export const AnnotationTitle = styled.div<{ isPlaceholder?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ isPlaceholder }) => (isPlaceholder ? '#828D9A' : '#1d1d35')};
`;

export const AnnotationSubtitle = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  text-align: right;
`;

export const AnnotationBorderRect = styled.div`
  position: absolute;

  width: 69%;
  height: 120px;

  border-radius: 8px;
  border-left: 1px dashed #009a47;
  border-top: 1px dashed #009a47;
  border-right: 1px dashed #ffbd13;
  border-bottom: 1px dashed #ffbd13;

  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 5%;

  @media (min-width: 768px) {
    width: 37%;
    left: calc(0.84 * 70%);
    right: initial;
    bottom: 20%;
    margin: 0;

    border-left-color: #ffbd13;
    border-top-color: #ffbd13;
    border-right-color: #009a47;
    border-bottom-color: #009a47;
    border-bottom-left-radius: 0;
  }
`;

const RectArrow = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RectLeftArrow = styled(RectArrow)`
  left: -6px;
  top: 30%;

  color: #009a47;

  @media (min-width: 768px) {
    color: #ffbd13;
  }
`;

export const RectTopArrow = styled(RectArrow)`
  left: 70%;
  top: -6px;

  color: #009a47;

  @media (min-width: 768px) {
    color: #ffbd13;
  }
`;

export const RectRightArrow = styled(RectArrow)`
  right: -6px;
  top: 70%;

  color: #ffbd13;

  @media (min-width: 768px) {
    color: #009a47;
  }
`;

export const RectBottomArrow = styled(RectArrow)`
  left: 30%;
  bottom: -6px;

  color: #ffbd13;

  @media (min-width: 768px) {
    color: #009a47;
  }
`;

export const BatteryToRectLine = styled.div`
  position: absolute;
  width: 1px;
  left: 84.3%;
  border-right: 1px dashed #ffbd13;
  bottom: calc(5% + 118px);

  height: calc(15px + 0.22 * (100% - 35px - 150px));

  @media (min-width: 768px) {
    display: none;
  }
`;

export const AnnotationRect = styled.div`
  position: absolute;

  height: 120px;
  width: 60%;

  bottom: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;

  @media (min-width: 768px) {
    bottom: 20%;
    width: 37%;
    left: calc(0.86 * 70%);
    right: initial;
    margin: 0;
  }
`;

export const AnnotationRectInner = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px;

  display: grid;
  grid-template-columns: min-content min-content;
  row-gap: 16px;
  column-gap: 24px;
  align-items: center;
  align-content: center;
  white-space: nowrap;
  justify-content: center;

  @media (min-width: 768px) {
    padding-left: 15%;
  }
`;

export const PointTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #ffffff;
  border: #f7f7fc solid 1px;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
`;

export const TooltipLabel = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
`;

export const TooltipValue = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
`;

export const AlertContainer = styled.div`
  margin-bottom: 24px;
`;

export const AlertList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  margin-top: -16px;
`;

export const AlertItem = styled.div`
  color: #d54855;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 400;
`;
