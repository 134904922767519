import { SYSTEM_METRIC_TITLES_MAP } from './constants';
import { SystemAlertCondition } from './types';

export const systemAlertConditionToString = (
  condition: SystemAlertCondition,
) => {
  return `${SYSTEM_METRIC_TITLES_MAP[condition.metric]} is ${
    condition.comparator
  } than ${condition.percent}% of ${condition.days} day${
    condition.days > 1 ? 's' : ''
  } moving average`;
};
