import { useQuery } from '@tanstack/react-query';
import { graphql } from '../gql/gql';
import { graphqlRequest } from '../api/graphqlRequest';
import { ReactQueryKeys } from './reactQueryKeys';
import { filterNotNull } from '../utils/types/filterNotNull';
import { SystemAlertCondition } from '../domains/systems';

const query = graphql(/* GraphQL */ `
  query Contacts {
    projects(filter: { type: { equalTo: "ACCOUNT" } }) {
      id

      systemsByProjectIdConnection {
        nodes {
          id
          uuid
          addressForced
          operationalAt
          lastReportAt
          createdAt
          operationStatus

          systemAlertsBySystemIdConnection(
            first: 5
            orderBy: [CREATED_AT_DESC]
          ) {
            nodes {
              id
              condition
            }
          }

          systemLogsBySystemIdConnection {
            aggregates {
              max {
                createdAt
              }
            }
          }
        }
      }
    }
    contacts {
      id
      name
      phones
      emails
      referralCode
    }
  }
`);

export const useMe = () => {
  return useQuery({
    queryKey: [ReactQueryKeys.Me],
    queryFn: async () => {
      const { contacts, projects } = await graphqlRequest(query);

      return {
        me: contacts?.[0],
        accountId: projects?.[0]?.id,
        systems: filterNotNull(
          projects?.[0]?.systemsByProjectIdConnection?.nodes ?? [],
        ).map((system) => ({
          ...system,
          systemAlertsBySystemIdConnection: {
            ...system.systemAlertsBySystemIdConnection,
            nodes: filterNotNull(
              system.systemAlertsBySystemIdConnection.nodes,
            ).map((alert) => ({
              ...alert,
              condition: alert?.condition as SystemAlertCondition,
            })),
          },
        })),
      };
    },
  });
};
